import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { color } from "../_globalSettings/skin"
import { typography } from "../_globalSettings/typography"

const StyledContactInfo = styled.div`
	font-size: 18px;
	line-height: 28px;
	display: flex;
	flex-direction: column;
	width: 34%;
	padding-left: 11.3%;
	.contact-info__headline {
		padding-bottom: 20px;
		font-size: 25px;
		line-height: 31px;
	}
	.contact-info__content {
		display: flex;
		flex-direction: column;
		padding-top: 48px;
		padding-bottom: 24px;
	}
	.contact-info__text {
		padding-left: 41px;
		background-repeat: no-repeat;
		background-position: 0 4px;
		display: inline-block;
		margin-bottom: 24px;
		${typography.BodyL};
		color: ${color.main.text1};
	}
	.contact-info__h1 {
		padding-bottom: 25px;
		font-size: 48px;
		line-height: 50px;
		font-weight: 500;
		padding-bottom: 15px;
		position: relative;
		color: ${color.black.default};
		&:before {
			content: "";
			display: inline-block;
			border-bottom: 4px solid ${color.blue.default};
			width: 20px;
			margin-right: 5px;
			position: absolute;
			left: -25px;
			top: 40px;
			@media (max-width: 1070px) {
				position: static;
			}
		}
	}
	.contact-info__p {
		font-size: 24px;
		line-height: 36px;
		padding-bottom: 25px;
	}
	.contact-info__link {
		color: black;
		text-decoration: none;
	}
	.c-point {
		background-image: url("/css/c-point.svg");
		order: 1;
	}
	.c-phone {
		background-image: url("/css/c-phone.svg");
		order: 2;
	}
	.c-mail {
		background-image: url("/css/c-mail.svg");
		order: 3;
	}
	.c-ic {
		background-image: url("/css/c-ic.svg");
		order: 4;
	}
	.c-datovka {
		background-image: url("/css/c-datovka.svg");
		order: 6;
	}
	.c-bank {
		background-image: url("/css/c-bank.svg");
		order: 5;
	}
	.contact-info__light {
		color: ${color.main.text3};
	}
	.contact-info__address {
		padding-right: 80px;
	}
	.contact-info__map {
		width: 100%;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
		padding-left: 0;
		.contact-info__wrapper {
			display: flex;
		}
		.contact-info__map {
			margin-top: 48px;
		}
	}
	@media (max-width: ${breakpoint.preMin.to}px) {
		h5 {
			padding-top: 56px;
		}
		.contact-info__wrapper {
			flex-direction: column;
		}
		.contact-info__map {
			margin-top: 0;
			margin-bottom: 40px;
			img {
				width: 100%;
			}
		}
	}
`

export default StyledContactInfo
