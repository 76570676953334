import React from "react"
import { string, shape, arrayOf, oneOfType, number } from "prop-types"
import StyledContactInfo from "./style"
import A from "../Link"
import Image from "../Image"
import { Heading5 } from "../_globalSettings/typography"

const ContactInfo = ({ image }) => {
	return (
		<StyledContactInfo>
			<Heading5>Asociace softwarových agentur z.s.</Heading5>
			<div className="contact-info__wrapper">
				<div className="contact-info__address">
					<div className="contact-info__content">
						<p className="contact-info__text c-point">
							Veletržní 820/73
							<br />
							<span>Holešovice</span>
							<br />
							<span className="contact-info__light">170 00, Praha 7</span>
						</p>
						<p className="contact-info__text c-phone">
							<A className="contact-info__link" href="tel:+420 721 133 788">
								+420 721 133 788
							</A>
						</p>
						<p className="contact-info__text c-mail">
							<A className="contact-info__link" href="mailto:info@aswa.cz">
								info@aswa.cz
							</A>
						</p>
						<p className="contact-info__text c-ic">
							<span className="contact-info__light">IČ: </span>
							090 47 905
						</p>
						<p className="contact-info__text c-bank">
							Fio banka, a.s.
							<br />
							2501792965/2010
						</p>
					</div>
				</div>
				<div className="contact-info__map">
					<Image data={image} alt="kontaktní mapa" />
				</div>
			</div>
		</StyledContactInfo>
	)
}

export const ContactInfoProps = {
	nodes: arrayOf(
		shape({
			image: oneOfType([
				string,
				shape({
					childImageSharp: shape({
						fluid: shape({
							aspectRatio: number,
							sizes: string,
							src: string,
							srcSet: string,
							// srcSetWebp: string,
							// srcWebp: string,
						}).isRequired,
					}),
				}),
			]).isRequired,
			alt: string.isRequired,
		})
	),
}

ContactInfo.propTypes = {
	...ContactInfoProps,
}

export default ContactInfo
