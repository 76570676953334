import React from "react"
import { string, shape, arrayOf, oneOfType, number } from "prop-types"
import StyledPerson from "./style"
import Image from "../Image"
import A from "../Link"
import { Heading5 } from "../_globalSettings/typography"

const Person = ({
	image,
	headline,
	summary,
	company,
	phone,
	mail,
	content,
}) => {
	return (
		<StyledPerson>
			<div className="person__left">
				<div className="person__image">
					<Image data={image} alt={headline} />
				</div>
				<div className="person__contact">
					<Heading5>{headline}</Heading5>
					<p className="person__summary">{summary}</p>
					<p className="person__company">{company}</p>
					{phone && phone.length >= 9 && (
						<p>
							<A href={`tel:${phone}`} className="person__phone">
								{phone}
							</A>
						</p>
					)}
					{mail && mail !== "" && (
						<p>
							<A href={`mailto:${mail}`} className="person__mail">
								{mail}
							</A>
						</p>
					)}
				</div>
			</div>
			<p className="person__text">{content}</p>
		</StyledPerson>
	)
}

export const PersonProps = {
	nodes: arrayOf(
		shape({
			image: oneOfType([
				string,
				shape({
					childImageSharp: shape({
						fluid: shape({
							aspectRatio: number,
							sizes: string,
							src: string,
							srcSet: string,
							// srcSetWebp: string,
							// srcWebp: string,
						}).isRequired,
					}),
				}),
			]).isRequired,
			alt: string.isRequired,
		})
	),
}

Person.propTypes = {
	...PersonProps,
}

export default Person
