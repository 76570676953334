import React from "react"
import { shape } from "prop-types"
import styled from "styled-components"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import Person from "../components/Person"
import ContactInfo from "../components/ContactInfo"
import { Heading1 } from "../components/_globalSettings/typography"
import { breakpoint } from "../components/_globalSettings/var"

const ContactWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 85px;
	.contact__content-wrapper {
		display: flex;
		padding-top: 100px;
	}
	.contact__left {
		width: 66%;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		.contact__content-wrapper {
			flex-direction: column;
		}
		.contact__left {
			width: 100%;
		}
	}

	@media (max-width: ${breakpoint.preMin.to}px) {
		padding-top: 40px;
		.contact__content-wrapper {
			padding-top: 64px;
		}
	}
`

const Contact = ({ pageContext }) => {
	return (
		<Layout {...pageContext}>
			<Row>
				<ContactWrapper>
					<Heading1>{pageContext.banner.headline}</Heading1>
					<div className="contact__content-wrapper">
						<div className="contact__left">
							{pageContext.people.nodes.map(person => (
								<Person {...person} key={person.headline} />
							))}
							<Person {...pageContext.person} />
						</div>
						<ContactInfo {...pageContext.banner} />
					</div>
				</ContactWrapper>
			</Row>
		</Layout>
	)
}

Contact.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Contact
