import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { color } from "../_globalSettings/skin"
import { typography } from "../_globalSettings/typography"

const StyledPerson = styled.div`
	padding-bottom: 64px;
	display: flex;
	.person__image {
		width: 100%;
		max-width: 149px;
		flex-grow: 0;
		flex-shrink: 0;
		padding-bottom: 30px;
		margin-right: 10.5%;
	}
	.person__contact {
		min-width: 39%;
		flex-shrink: 0;
		flex-grow: 1;
		border-bottom: 1px solid ${color.light.n500};
	}
	.person__left {
		display: flex;
		width: 52.7%;
	}
	h5 {
		padding-bottom: 12px;
	}
	.person__summary {
		color: ${color.main.text2};
		padding-bottom: 8px;
		${typography.AllCapsBoldS};
		font-weight: 500;
	}
	.person__company {
		${typography.BodyM};
		color: ${color.main.text2};
		margin-bottom: 12px;
	}
	.person__phone {
		padding-bottom: 10px;
		display: inline-block;
		padding-left: 30px;
		background-image: url("/css/c-phone.svg");
		background-repeat: no-repeat;
		${typography.BodyM};
		color: ${color.main.text1};
		text-decoration: none;
	}
	.person__mail {
		padding-bottom: 8px;
		display: inline-block;
		padding-left: 30px;
		background-image: url("/css/c-mail.svg");
		background-repeat: no-repeat;
		${typography.BodyS};
		color: ${color.main.text1};
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.person__text {
		${typography.BodyL};
		color: ${color.main.text1};
		padding-left: 5.8%;
		width: 47.3%;
		padding-bottom: 40px;
		border-bottom: 1px solid ${color.light.n500};
	}
	@media (max-width: ${breakpoint.preMax.to}px) {
		flex-direction: column;
		.person__text {
			padding-left: 0;
			width: 100%;
		}
		.person__contact {
			border: 0;
		}
		.person__left {
			width: 100%;
		}
		.person__image {
			margin-right: 48px;
		}
	}
	@media (max-width: ${breakpoint.min.to}px) {
		.person__left {
			flex-direction: column;
		}
	}
`

export default StyledPerson
